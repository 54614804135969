@use '../../styles/variables';
@use '../../styles/mixins';

.body-parts {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.front-body, .back-body {
    display: inline-grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
}

.front-body {
    grid-column: 1 / 2;
    margin-left: auto;
    margin-right: 30px;

    @include mixins.rwd(600px) {
        grid-column: 1 / 3;
        margin-right: auto;
    }
}

.back-body {
    grid-column: 2 / 3;
    margin-left: 30px;
    margin-right: auto;
    @include mixins.rwd(600px) {
        grid-column: 1 / 3;
        margin-left: auto;
    }
}
.body-part {
    width: 195px;
    height: 500px;
    background-color: variables.$nude;
    grid-row-start: 2;
    grid-column-start: 1;
    margin: auto;
}

.body-part.checked {
    background-color: variables.$dark-nude;
}

.head {
    clip-path: polygon(43.85%
        12.6%,
        37.45%
        8.5%,
        37.45%
        4.1%,
        41.03%
        1%,
        50%
        0.1%,
        58.97%
        1.2%,
        62.56%
        4.6%,
        61.79%
        8.8%,
        56.92%
        12.6%,
        56.92%
        14.5%,
        43.85%
        14.5%);
}

.corpus {
    clip-path: polygon(24.7%
        16.9%,
        43.85%
        14.5%,
        56.67%
        14.5%,
        77.18%
        17.1%,
        69.49%
        34.3%,
        74.36%
        48.1%,
        25.13%
        48.2%,
        30.77%
        34.2%);
}

.left-arm {
    clip-path: polygon(15.38% 18.5%, 24.87% 16.9%, 29.3% 29.5%, 25.13% 40.9%, 18.46% 51.1%, 18.97% 58.6%, 8.72% 60.1%, 0.26% 54.4%, 10.77% 51.6%);
}

.right-arm {
    clip-path: polygon(77.18%
        17.1%,
        84.36%
        19%,
        89.49%
        51.5%,
        98.72%
        54.8%,
        92.54%
        60.3%,
        80.77%
        58.9%,
        80.77%
        51.6%,
        74.1%
        39%,
        71.28%
        29.9%);
}

.crotch {
    clip-path: polygon(39.5%
        48%,
        61.5%
        48%,
        50%
        56.8%);
}

.left-leg {
    clip-path: polygon(25.1%
        48%,
        39.5%
        48%,
        50%
        56.8%,
        46.15%
        99.9%,
        31.79%
        98.5%,
        38.46%
        92.9%,
        29.23%
        77.1%,
        31.28%
        70.5%);
}

.right-leg {
    clip-path: polygon(61.5%
        48%,
        74.4%
        48%,
        68.72%
        70.4%,
        70%
        77.5%,
        61.79%
        93.2%,
        68.46%
        98.4%,
        53.33%
        99.9%,
        50%
        56.8%);
}

.back.left-leg {
clip-path: polygon(25.1% 48%,
            50% 48%,
            50% 57.4%,
            46.15% 99.9%,
            31.79% 98.5%,
            38.46% 92.9%,
            29.23% 77.1%,
            31.28% 70.5%);
}

.back.right-leg {
clip-path: polygon(50% 48%,
            74.4% 48%,
            68.72% 70.4%,
            70% 77.5%,
            61.79% 93.2%,
            68.46% 98.4%,
            53.33% 99.9%,
            50.51% 56.8%);
}

.body-legend {
    display: flex;
    justify-content: center;
    @include mixins.small {
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    p {
        @include mixins.small {
                flex: 75%;
        }
    }
}
.legend {
    width: 30px;
    height: 30px;
    margin: 10px;
}

.affected {
    background-color: variables.$dark-nude;
}

.unaffected {
    background-color: variables.$nude;
}