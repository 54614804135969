@use '../../styles/variables';
section.form-nav {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 30px;
}

.form-nav p {
    grid-column: 2 / 3;
    margin: auto;
}

section.form-nav button[value="prev"] {
    grid-column: 1 / 2;
    margin-right: 10px;
    margin-left: auto;
}

section.form-nav button[value="next"], section.form-nav button[value="done"] {
    grid-column: 3 / 4;
    margin-left: 10px;
}
section.form-nav button {
    max-width: 100px;
}