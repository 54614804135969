@use './variables';
@use './mixins';

body {
    width: 100%;
    margin: 0;
}

main {
    box-sizing: border-box;
    width: 100%;
    max-width: 1000px;
    padding: 50px;
    margin: 0 auto;
}

main.grid {
    display: grid;
    gap: 20px;

    @include mixins.medium {
        gap: 10px;
        padding: 0 10px;
    }
}

main.third {
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 1300px;
    @include mixins.large {
        grid-template-columns: 1fr 1fr;
    }
}

section {
    @include mixins.medium {
        grid-column: 1 / 3;
    }
}

section.full-width {
    grid-column: 1 / 3;
}