@use '../../styles/variables';

ul.checkbox-list {
    padding: 0 10px;
    li {
        display: grid;
        grid-template-columns: 3fr 3fr 1fr;
        margin: 10px 0;
        align-items: center;
        gap: 10px;
        max-width: 400px;
    }
}

.frequency-checkboxes {
    grid-column: 2 / 3;
}

.frequency-checkboxes input {
    width: 25px;
    height: 25px;
}

#last-notes ul {
    padding: 0 15px;

    li {
        padding: 15px 0;
        position: relative;
        list-style: none;
        line-height: 1.5;
        
        span {
            font-weight: 500;
            display: inline-block;
            padding-bottom: 7px;
        }
    }
    
    li:not(:last-child) {
        border-bottom: 1px solid variables.$dark-nude;
    }
}


