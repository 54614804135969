@use "./variables";

* {
    font-family: variables.$font-stack;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
}

h1 {
    margin: 20px 20px 20px;
    text-align: center;
}

header {
    text-align: center;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    max-width: 1200px;
    align-items: center;
    margin: 0 auto;
}

header img {
    max-width: 100%;
    max-height: 150px;
}