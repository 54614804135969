 @use '../../styles/variables';

.error {
    display: none;
    color: red;
}

.error.visible {
    display: block;
}

form.sign-user {
    input:not([type="submit"]) {
        width: 100%;
        margin-bottom: 20px;
        height: 2rem;
        
    }
        input:focus-visible {
            outline-color: variables.$nude;
        }
}