@use '../../styles/variables';
$size: 20px;

button.remove {
    background-color: variables.$nude;
    width: $size;
    height: $size;
    line-height: $size;
    padding: 0;
    text-align: center;
    justify-self: end;
    font-size: 0.7rem;
}

button.remove:hover {
    background-color: variables.$dark-nude;
}

%dimensions {
    width: 25px;
    height: 25px;
}

#last-notes button.remove {
    @extend %dimensions;
    position: absolute;
    right: 0px;
    top: 10px;
    
}

#notes button.remove {
    @extend %dimensions;
    float: right;
}

