@use '../../styles/variables';
@use '../../styles/mixins';

.adding-form {
    border-top: 1px solid variables.$dark-nude;
    padding-top: 20px;
    display: grid;
    grid-template-columns: 4fr 3fr 1fr;
    gap: 15px;
    align-items: end;

    input {
        height: 20px;
        width: 100%;
    }
    
    label {
        font-size: 0.7rem;
    }
    
    textarea {
        width: 100%;
    }
    
    button[type="submit"] {
        width: 45px;
        height: 45px;
        line-height: 45px;
        padding: 0;
        border-radius: 25px;
        justify-self: end;
        font-size: 1.5rem;
        color: white;
        @include mixins.small {
            grid-column: 3 / 4;
        }
    }
    .input-field.frequency {
        grid-column: 2 / 3;
        @include mixins.small {
            grid-column: 1 / 4;
        }
    }
    .input-field {
        grid-column: 1 / 2;
        @include mixins.small {
            grid-column: 1 / 4;
        }
    }
}

#events .adding-form .input-field, #last-notes .adding-form .input-field {
    grid-column: 1 / 3;
}

button,
.button {
    border: none;
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.3);
    transition: all 0.1s;
    background-color: variables.$green;
    padding: 15px;
    font-size: 1rem;
}

button:hover,
.button:hover {
    background-color: variables.$dark-green;
}

#navigation {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    a {
        display: inline-block;
        padding: 15px;
        font-size: 1rem;
        text-decoration: none;
        color: black;
        flex-basis: content;
        margin-bottom: 20px;
    }
}

#dashboard section:not(:first-child) {
    margin-top: 30px;
}

section.frame {
        border: 2px solid variables.$dark-green;
            padding: 20px;
}

main#notes {
    section {
        p {
             margin: 0;
             line-height: 1.5;
        }
        h2 {
            display: inline-block;
            margin: 0 0 15px 0;
            font-size: 1.2rem;
            font-weight: 500;
        }
    }

    .adding-form {
        border-top: none;
        padding-top: 0;
        display: block;
        
        .input-field {
            min-height: 50px;
        }
        button[type="submit"] {
            float: right;
        }
        textarea {
            height: 130px;
        }
    }
}

